import "./index.scss";

import { Menu } from "antd";
import { useTranslation } from "react-i18next";

import { SvgIcon } from "@/shared/icons";
import { useUserRoleStore } from "@/store/userStore";

type MenuItem = {
  label: string;
  icon: JSX.Element;
  key: string;
};

type SidebarNavMenuProps = {
  currentMenuItem: string;
  onMenuItemClick: (e: { key: React.Key }) => void;
};

export const SidebarNavMenu = ({ currentMenuItem, onMenuItemClick }: SidebarNavMenuProps) => {
  const { t } = useTranslation();
  const role = useUserRoleStore((state) => state.role);
  const isTechnician = role.toLowerCase() === "technician";

  const allItems: MenuItem[] = [
    {
      label: t("sidebar.projects"),
      icon: <SvgIcon type="projects" className="sidebar-icon" />,
      key: "projects",
    },
    {
      label: t("sidebar.service_calls"),
      icon: <SvgIcon type="serviceCalls" className="sidebar-icon" />,
      key: "service-calls",
    },
    {
      label: `${isTechnician ? "My Task" : "All Tasks"}`,
      icon: <SvgIcon type="tasks" className="sidebar-icon" />,
      key: "tasks",
    },
    {
      label: t("sidebar.customers"),
      icon: <SvgIcon type="customers" className="sidebar-icon" />,
      key: "customers",
    },
    {
      label: t("sidebar.invoices"),
      icon: <SvgIcon type="invoices" className="sidebar-icon" />,
      key: "invoices",
    },
    {
      label: t("sidebar.team"),
      icon: <SvgIcon type="team" className="sidebar-icon" />,
      key: "team",
    },
    {
      label: t("sidebar.timesheets"),
      icon: <SvgIcon type="timesheets" className="sidebar-icon" />,
      key: "timesheets",
    },
  ];

  const items = role.toLowerCase() === "technician"
    ? allItems.filter((item) => item.key === "tasks" || item.key === "timesheets")
    : role.toLowerCase() === "accountant"
      ? allItems.filter((item) => item.key === "invoices")
      : role.toLowerCase() === "project_manager"
        ? allItems.filter((item) =>  item.key === "tasks" || item.key === "invoices")
        : role.toLowerCase() === "coordinator"
          ? allItems.filter((item) =>  item.key === "service-calls" || item.key === "invoices" || item.key === "projects" || item.key === "tasks")
          : role.toLowerCase() === "sales_person"
            ? allItems.filter((item) =>  item.key === "team" )
            : allItems;

  return (
    <Menu
      className="sidebar-nav-menu"
      mode="inline"
      items={items}
      selectedKeys={[currentMenuItem]}
      onClick={onMenuItemClick}
    />
  );
};
