import "./index.scss";

import { Avatar, Menu, Typography } from "antd";
import { useTranslation } from "react-i18next";

import { SvgIcon } from "@/shared/icons";
import { useUserNameStore, useUserPictureStore, useUserRoleStore } from "@/store/userStore";

type MenuItem = {
  label: string | JSX.Element;
  icon?: JSX.Element;
  key: string;
};

type SidebarBottomMenuProps = {
  collapsed: boolean;
  currentMenuItem: string;
  onMenuItemClick: (e: { key: React.Key }) => void;
};

export const SidebarBottomMenu = ({ collapsed, currentMenuItem, onMenuItemClick }: SidebarBottomMenuProps) => {
  const { t } = useTranslation();
  const role = useUserRoleStore((state) => state.role);
  const name = useUserNameStore((state) => state.name);
  const picture = useUserPictureStore((state) => state.url);

  const avatarIcon = picture  ? (
    <Avatar className={`${role.toLowerCase()}`} src={picture} size="small" />
  ) : (
    <Avatar className={`${role.toLowerCase()}`}>
      <SvgIcon type="imgFiller" width={13.5} height={13.5}/>
    </Avatar>
  );

  const items: MenuItem[] = [
    {
      label: t("sidebar.notifications"),
      icon: <SvgIcon type="notifications" className="sidebar-icon" />,
      key: "notifications",
    },
    {
      label: <Typography.Paragraph className={`${role.toLowerCase()}`}>{name}</Typography.Paragraph>,
      icon: avatarIcon,
      key: "profile",
    },
    {
      label: (
        <Typography.Paragraph className={`role ${role.toLowerCase()}`}>
          {collapsed ? role.slice(0, 2) : role.toLowerCase().replace(/_/g, " ")}
        </Typography.Paragraph>
      ),
      key: "role",
    },
  ];

  return (
    <Menu
      className="sidebar-bottom-menu"
      mode="inline"
      items={items}
      selectedKeys={[currentMenuItem]}
      onClick={onMenuItemClick}
    />
  );
};
