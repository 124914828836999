import { create } from "zustand";

interface UserIdState {
  id: string;
  setId: (role: string) => void;
}

export const useUserIdStore = create<UserIdState>((set) => ({
  id: "-",
  setId: (id) => set({ id }),
}));

interface UserRoleState {
  role: string;
  setRole: (role: string) => void;
}

export const useUserRoleStore = create<UserRoleState>((set) => ({
  role: "-",
  setRole: (role) => set({ role }),
}));

interface UserNameState {
  name: string;
  setName: (role: string) => void;
}

export const useUserNameStore = create<UserNameState>((set) => ({
  name: "-",
  setName: (name) => set({ name }),
}));

interface UserPictureState {
  url: string;
  setPicture: (role: string) => void;
}

export const useUserPictureStore = create<UserPictureState>((set) => ({
  url: "-",
  setPicture: (url) => set({ url }),
}));
