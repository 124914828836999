import "./index.scss";

import { Layout as BaseLayout } from "antd";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Logo } from "@/components/ui/Logo";
import { SvgIcon } from "@/shared/icons";

import { SidebarBottomMenu } from "./ui/SidebarBottomMenu";
import { SidebarNavMenu } from "./ui/SidebarNavMenu";
import { useUserRoleStore } from "@/store/userStore";

const { Sider } = BaseLayout;

export const Sidebar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [current, setCurrent] = useState("");
  const [showMenus, setShowMenus] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const role = useUserRoleStore((state) => state.role);

  useEffect(() => {
    const path = location.pathname.split("/")[1];

    if (path) {
      setCurrent(path);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (role !== undefined && role !== null) {
      const timer = setTimeout(() => {
        setShowMenus(true);
      }, 10);

      return () => clearTimeout(timer);
    }
  }, [role]);

  const onMenuItemClick = (e: { key: React.Key }) => {
    if (e.key !== "notifications" && e.key !== "role") {
      setCurrent(e.key.toString());
      navigate(`/${e.key}`);
    }
  };

  if (role === undefined || role === null) {
    return null;
  }

  return (
    <Sider
      className="sidebar"
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      width={220}
      collapsedWidth={100}
      trigger={<SvgIcon type="sidebarCollapseArrow" style={{ transform: collapsed ? "rotate(180deg)" : "unset" }} />}
      style={{ padding: collapsed ? "40px" : "40px 20px 40px 40px" }}
    >
      <Logo size={collapsed ? "short" : "long"} />
      {showMenus && (
        <>
          <SidebarNavMenu currentMenuItem={current} onMenuItemClick={onMenuItemClick} />
          <SidebarBottomMenu collapsed={collapsed} currentMenuItem={current} onMenuItemClick={onMenuItemClick} />
        </>
      )}
    </Sider>
  );
};
