import "./shared/locales/i18n";

import BugsnagPerformance from "@bugsnag/browser-performance";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";

import App from "./components/app";

const apiKey = `${import.meta.env.BUGSNAG_KEY ?? "f751b2d098a08ed2d6e3b0d35616c4e7"}`;

Bugsnag.start({
  apiKey,
  plugins: [new BugsnagPluginReact()],
});
BugsnagPerformance.start({ apiKey });

const ErrorBoundary = Bugsnag.getPlugin("react")!.createErrorBoundary(React);

const rootElement = document.querySelector("#root") as HTMLElement;

createRoot(rootElement).render(
  <StrictMode>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </StrictMode>
);
